<template>
    <div>
        <div class="flex items-center justify-center helper-box">
            <p>Select a template for your website from the options below.  After you have selected your template, you will be given the option to customize the colors and fonts.</p>
        </div>
        <div class="flex flex-col flex-1 h-full mt-8">
            <div class="grid grid-cols-6 gap-6">
                <div class="col-span-2 template-card-wrapper" v-for="(template, index) in allGlobalTemplates" :key="index">
                    <div class="col-span-2">
                        <p class="font-bold tracking-wider font-inter text-highlight-900 text-sm">{{ templateNames[index] }}</p>
                        <p class="text-xs tracking-normal mt-2 leading-tight overflow-auto mb-4">{{ templateDescription[index] }}</p>
                    </div>
                    <div class="h-64 overflow-auto col-span-2 bg-gray-100 cursor-pointer"
                    :class="{ 'border-4 border-highlight-base': template.name === selectedTemplate}"
                    @click="selectedTemplate = template.name">
                        
                        <img
                            :src="require(`@/assets/theme/${template.thumbnail_url}`)"
                            class="w-full"
                        >
                    </div>
                </div>
            </div>
            <div class="mt-12">
                <p class="font-bold tracking-wider font-inter text-highlight-900 text-sm">customizations</p>
                <div class="flex mt-4">
                    <div class="w-1/3">
                        <p class="text-xs tracking-widest font-frank font-semibold">font combination</p>
                        <select class="border px-4 py-2 mt-2 w-full" v-model="selectedFontOption">
                            <option v-for="(option, idx) in fontOptions" :key="idx" :value="option">
                                {{ formatFontText(option.primaryFont) }} & {{ formatFontText(option.secondaryFont) }}
                            </option>
                        </select>
                        <a href="#" @click.prevent="switchFonts()" class="text-link text-link_small mt-2">switch primary and secondary font</a>
                    </div>
                    <div class="w-2/3">
                        <img class="mx-auto" :src="fontPreviewImg" />
                    </div>
                </div>
            </div>
            <div class="mt-8">
                <p class="text-xs tracking-widest font-frank font-semibold">color pallet</p>
                <div class="grid grid-cols-6 gap-6 mt-2">
                    <img v-for="idx in 5" :key="idx"
                        :class="{ 'border-4 border-highlight-base': selectedColorOption.idx === idx-1}"
                        @click="setColorOption(idx-1)" class="h-32" :src="require(`@/assets/theme/palette-${idx}.png`)" />
                    <div class="grid grid-cols-1 relative cursor-pointer" @click="openTemplateCustomColors"
                         :class="{ 'border-4 border-highlight-base': selectedColorOption.idx === 'custom'}">
                        <span class="absolute w-full text-center text-white custom-color-label" title="custom color">custom color</span>
                        <div :style="{background: customColors.primaryColor}"></div>
                        <div :style="{background: customColors.secondaryColor}"></div>
                    </div>
                </div>
            </div>
        </div>
        <modal-footer :primary="save"></modal-footer>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import ModalNavigation from "@/mixins/ModalNavigation";
    import ModalFooter from "@/components/ui/modals/ModalFooter";
    import EventBus from "@/utils/EventBus";

    export default {
        components: { ModalFooter },
        mixins: [ ModalNavigation ],
        data: () => {
            return {
                website: {},
                websiteInfo: {},
                templateNames: [
                    'the rigor',
                    'the hirst',
                    'the urbana'
                ],
                templateDescription: [
                    "Minimalistic design and monochromatic colors use strategic white space. Images and copy purposely don't overlap for a clean and aligned look.",
                    "Elegant and stylistic details frame your property’s best features with a noticeably expensive feel and format.",
                    "Slender lines and delicate touches of personality create a cohesive user experience. Selective colors complement the site's light ambiance."
                ],
                selectedTemplate: null,
                selectedFontOption: {},
                fontOptions: [
                    {
                        primaryFont: 'helvetica',
                        secondaryFont: 'georgia',
                    },
                    {
                        primaryFont: 'lato',
                        secondaryFont: 'lora',
                    },
                    {
                        primaryFont: 'quattrocento_sans',
                        secondaryFont: 'quattrocento',
                    },
                    {
                        primaryFont: 'roboto_slab',
                        secondaryFont: 'roboto',
                    },
                    {
                        primaryFont: 'source_sans',
                        secondaryFont: 'source_serif',
                    }
                ],
                selectedColorOption: {},
                colorOptions: [
                    {
                        primaryColor: '#1d2638',
                        secondaryColor: '#9f8f61',
                    },
                    {
                        primaryColor: '#132643',
                        secondaryColor: '#468189',
                    },
                    {
                        primaryColor: '#6f6965',
                        secondaryColor: '#d0d0d0',
                    },
                    {
                        primaryColor: '#080f0f',
                        secondaryColor: '#a52422',
                    },
                    {
                        primaryColor: '#37423d',
                        secondaryColor: '#754043',
                    }
                ],
                customColors: {
                    primaryColor: '#ededed',
                    secondaryColor: '#ededed'
                }
            }
        },
        computed: {
            ...mapGetters({
                allGlobalTemplates: 'templates/getGlobalTemplates'
            }),

            fontPreviewImg() {
                let image = require(`@/assets/theme/${this.fontOptions[0].primaryFont}+${this.fontOptions[0].secondaryFont}.png`)
                if (Object.keys(this.selectedFontOption).length) {
                    image = require(`@/assets/theme/${this.selectedFontOption.primaryFont}+${this.selectedFontOption.secondaryFont}.png`)
                }
                return image.replace('+', '%2B')
            }
        },
        methods: {
            ...mapActions({
                updateWebsiteTemplateInStore: 'websites/updateWebsiteTemplate',
                notifySuccess: 'alerts/notifySuccess',
                notifyError: 'alerts/notifyError',
            }),
            formatFontText(value) {
                if (!value) return ''
                value = value.toString().charAt(0).toUpperCase() + value.slice(1)
                return value.replace('_', ' ')
            },

            switchFonts() {
                let switchOption = this.fontOptions.find(option => option.primaryFont === this.selectedFontOption.primaryFont);
                let newPrimary = switchOption.secondaryFont;
                let newSecondary = switchOption.primaryFont;
                switchOption.primaryFont, this.selectedFontOption.primaryFont = newPrimary;
                switchOption.secondaryFont, this.selectedFontOption.secondaryFont = newSecondary;
            },

            setColorOption(index) {
                this.selectedColorOption = this.colorOptions[index]
                this.selectedColorOption.idx = index
            },

            setCustomColorOptions(colors) {
                this.customColors.primaryColor = colors[0];
                this.customColors.secondaryColor = colors[1];

                this.selectedColorOption = this.customColors;
                this.selectedColorOption.idx = 'custom';
            },

            setColorAndFontOptions() {
                let colorOption = this.colorOptions.findIndex(option => option.primaryColor === this.website.primary_color);

                if (colorOption >= 0) {
                    this.setColorOption(colorOption)
                } else {
                    this.setCustomColorOptions([
                        this.website.primary_color,
                        this.website.secondary_color
                    ]);
                }

                let fontOption = this.fontOptions.find(option => option.primaryFont === this.website.primary_font);

                if (fontOption) {
                    this.selectedFontOption = fontOption;
                } else {
                    this.selectedFontOption = this.fontOptions[0];
                }
            },
            openTemplateCustomColors() {
                EventBus.emit('open-modal-colors-selector', {
                    title: 'Custom Color Palletes',
                    selectorsAmount: 2,
                    selectorsLabels: ['primary color', 'secondary color'],
                    selectorsDefaultColors: [this.customColors.primaryColor, this.customColors.secondaryColor],
                });
            },
            async save(redirect = true) {
                const payload = {};
                payload.customers_id = this.website.customers_id;
                payload.communities_id = this.websiteInfo.communities_id;
                payload.websites_id = this.website.websites_id;
                payload.old_templates_name = this.website.templates_name;
                payload.templates_name = this.selectedTemplate;
                payload.is_custom = false;
                payload.primary_color = this.selectedColorOption.primaryColor;
                payload.secondary_color = this.selectedColorOption.secondaryColor;
                payload.primary_font = this.selectedFontOption.primaryFont;
                payload.secondary_font = this.selectedFontOption.secondaryFont;
                let params = {
                    websiteId: this.website.websites_id,
                    id: payload.old_templates_name,
                    data: payload
                };

                this.$cmsDataProvider.update('updateWebsiteTemplate', params).then( () => {
                    this.notifySuccess('The template was updated successfully');

                    if (redirect) {
                        this.$router.push( { name: 'deploys.create', params: {websiteId: this.website.websites_id, forcePull: true } } );
                    }
                }).catch(() => {
                    this.notifyError('There was an error saving the information');
                });
            }
        },
        async mounted() {
            await this.$cmsDataProvider.get('websiteTemplate', {websiteId: this.$route.params.id})
            .then((response) => {
                this.website = response[0];
            }).catch(() => {
                this.notifyError('There was an error getting the website template ');
            });
            this.selectedTemplate = this.website.templates_name;
            this.setColorAndFontOptions();
            EventBus.on('modal-colors-selector-submitted', data => {
                this.setCustomColorOptions(data.selectedColors)
                this.save(false);
            });
        },
    }
</script>
<style scoped>
.template-card-wrapper{
    display: grid;
    grid-template-rows: 1fr 16rem;
    grid-template-columns: auto;
}

.custom-color-label {
    top: 50%;
    transform: translate(0, -50%);
}
</style>